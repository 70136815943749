import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Footer from "../components/footer";
import styled from "styled-components";
import GlobalStyles from "../styles/global";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 1000,
    easing: "easeInOut",
    clip: true,
    offset: 100,
    updateURL: true,
    popstate: true
  });
}

const Content = styled.div`
  margin: 0 auto;
  max-width: var(--content-width);
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
`;

const Page = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 0;
`;

const SiteLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <GlobalStyles />
        <Page>
          <Header headerText={data.site.siteMetadata.title} />
          <Content>{children}</Content>
          <Footer />
        </Page>
      </div>
    )}
  />
);

export default SiteLayout;
