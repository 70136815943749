import React from "react";

import { FaTwitter, FaGithub, FaGitlab, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

const FooterBar = styled.div`
  background-color: var(--dark-gray);
  flex-shrink: 0;
  z-index: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  height: 4rem;
  color: white;

  h3 {
    font-weight: 200;
  }
`;

const SocialIcons = styled.div`
  padding: 5px;
  display: flex;
  margin-left: 1rem;

  .social-icon {
    margin: 0 10px;
    transform: scale(1);
    transition: all 500ms ease;
  }

  .social-icon:hover {
    transform: scale(1.3);
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
  }

  ul li {
    display: inline;
  }

  ul li:hover {
  }

  a,
  a:active,
  a:visited {
    color: white;
  }

  a:hover {
    color: var(--accent-color);
  }
`;

const Footer = (props) => {
  return (
    <FooterBar>
      <h3>© Igor Schouten, 2019</h3>
      <SocialIcons>
        <Zoom cascade>
          <ul>
            <a
              href="https://github.com/ischouten"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaGithub className="social-icon" />
              </li>
            </a>
            <a
              href="https://gitlab.com/ischouten"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaGitlab className="social-icon" />
              </li>
            </a>
            <a
              href="https://twitter.com/igorschouten"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaTwitter className="social-icon" />
              </li>
            </a>
            <a
              href="https://linkedin.com/igorschouten"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaLinkedin className="social-icon" />
              </li>
            </a>
          </ul>
        </Zoom>
      </SocialIcons>
    </FooterBar>
  );
};

export default Footer;
