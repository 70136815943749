import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700');

  * {
    box-sizing: border-box;
  }

  :root {
    --main-color: rgb(246, 223, 195);
    --header-color: rgb(255,255,255);
    --accent-color: rgb(0, 173, 216);
    --content-width: 1200px;
    --shadow: 0 1px 8px 0 rgba(148, 148, 148, 1);
    --dark-gray: #666666;
    --light-gray: #CCCCCC;
    --extra-dark: #333333;

  }

  html, body {
    margin: 0;
    padding: 0
  }

  body {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 200;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 200
  }

  a {
    text-decoration: none;
    color: var(--dark-gray);
  }

  a:visited {
    color: var(--dark-gray);
  }

  a:hover, a:active {
    color: var(--accent-color);
  }


  img {
    width: 100%;
  }

  div.section {
    width: 100vw;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  div.section > div {
    margin: 0;
    width: var(--content-width);
    padding: 2rem;
  }

  .bg-dark {
    background-color: var(--extra-dark);
    color: white;
  }

  .bg-accent {
    background-color: var(--accent-color);
    color: white;
  }

  img.rounded {
    border-radius: 1rem;
  }

`;

export default GlobalStyles;
