import React from "react";
import styled from "styled-components";

const Bar = styled.div`
  background-color: var(--dark-gray);
  height: 2px;
  width: 2rem;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const Hamburger = () => {
  return (
    <Container>
      <Bar />
      <Bar />
      <Bar />
    </Container>
  );
};

export default Hamburger;
