import React from "react";
import styled, { keyframes } from "styled-components";
import Layout from "../components/layout";
import Head from "../components/head";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

const grow = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
`;

const shrink = keyframes`
  from {
    transform: scale(1.05);
  }

  to {
    transform: scale(1);
  }
`;

const ArticleSection = styled.div`
  padding: 0.5rem;
  width: 90%;
  box-shadow: var(--shadow);
  margin: 1rem 0;
  animation: ${shrink} 0.3s forwards;

  :hover {
    color: var(--default-color);
    animation: ${grow} 0.3s forwards;
  }
`;

const ArticleContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
`;

const SLink = styled(Link)`
  text-decoration: none;
  :hover {
    color: var(--default-color);
  }
`;

const SImage = styled(Img)`
  grid-column-start: 1;
  grid-column-end: 1;
`;

const PostSummary = styled.div`
  grid-column-start: 2;
  grid-column-end: 4;
`;

// Gatsby automagically maps the query data into data, so that it can be used here.
const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Head title="Blog" />
      <h2>Most recent items</h2>
      {data.allMarkdownRemark &&
        data.allMarkdownRemark.edges.map(({ node }, index) => {
          return (
            <ArticleSection key={node.id}>
              <SLink to={node.fields.slug}>
                <ArticleContent>
                  {node.frontmatter.featuredImage && (
                    <SImage
                      sizes={
                        node.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />
                  )}
                  <PostSummary>
                    <h3>{node.frontmatter.title}</h3>
                    <p>{node.excerpt}</p>
                    <p>{node.frontmatter.date}</p>
                  </PostSummary>
                </ArticleContent>
              </SLink>
            </ArticleSection>
          );
        })}
      <h4>
        Articles displayed:{" "}
        {data.allMarkdownRemark ? data.allMarkdownRemark.totalCount : 0}
      </h4>
    </Layout>
  );
};

// Query to retrieve information from markdown files
export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/(blog)/" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 300) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          excerpt
        }
      }
      totalCount
    }
  }
`;

export default BlogPage;
