import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Menu = styled.div`
  display: flex;

  /* Mobile  */
  @media (max-width: 960px) {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;

const MenuItem = styled.h3`
  margin: 0 10px;
  font-weight: 400;
`;

const active = {
  color: "rgb(0, 173, 216)"
};

const Items = () => {
  return (
    <Menu>
      <MenuItem>
        <Link to="/" activeStyle={active}>
          Home
        </Link>
      </MenuItem>
      {/* <MenuItem>
        <Link to="/#blog" activeStyle={active}>
          Blog
        </Link>
      </MenuItem> */}
      <MenuItem>
        <Link to="/#projects" activeStyle={active}>
          Projects
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/#about" activeStyle={active}>
          About
        </Link>
      </MenuItem>
    </Menu>
  );
};

export default Items;
