import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import logo from "../../static/igorschouten.svg";
import Hamburger from "../components/hamburger";
import MenuItems from "../components/menuItems";

// Animations
const makeClear = keyframes`
  from {
    box-shadow: none;
  }

  to {
    box-shadow: --var(shadow);
  }
`;

const makeSubtle = keyframes`
  from {
    box-shadow: --var(shadow);
  }
  to {
    box-shadow: none;
  }
`;

const mobileNavShow = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
`;

// const mobileNavHide = keyframes`
//   from {
//     opacity: 100;
//   }

//   to {
//     opacity: 0;
//   }
//`;

// Styled components
const HeaderBar = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--header-color);
  min-width: 100vw;
  display: flex;
  justify-content: center;
  box-shadow: var(--shadow);
  height: 5rem;
  animation: ${makeSubtle} forwards 2s;
  z-index: 1;

  :hover {
    animation: ${makeClear} forwards 0.5s;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
  width: 100%;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;

const LogoBox = styled.div`
  height: 100%;
  margin-left: 5rem;
  display: flex;
  align-items: center;
`;

const HeaderImage = styled.img`
  height: 100%;
  width: 100%;

  @media (max-width: 960px) {
    max-width: 80%;
  }
`;

const MenuToggleButton = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: block;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    left: 1.25rem;
    top: 1.25rem;

    :hover {
      cursor: pointer;
    }
  }
`;

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  @media (max-width: 960px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  @media (max-width: 960px) {
    display: block;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 5rem);
    margin-top: 5rem;
    opacity: 0;
    background-color: #ffffff;
    animation: ${mobileNavShow} forwards 0.5s;
  }
`;

// React part

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuShown: false };
  }

  toggleMenu = () => {
    const menu = document.querySelector(".menu");
    // TODO: Hiding this menu with the animation is not working yet.
    if (menu) {
      // console.log(menu);
      menu.style.animation = "mobileNavHide 0.5s forwards";
    }

    this.setState({ menuShown: !this.state.menuShown });
  };

  render() {
    return (
      <HeaderBar>
        <HeaderContent>
          <MenuToggleButton onClick={this.toggleMenu}>
            <Hamburger />
          </MenuToggleButton>
          <Link to="/">
            <LogoBox>
              <HeaderImage src={logo} alt="Logo" />
            </LogoBox>
          </Link>

          {this.state.menuShown && (
            <MobileMenu onClick={this.toggleMenu} className="menu">
              <MenuItems />
            </MobileMenu>
          )}
          <DesktopMenu>
            <MenuItems />
          </DesktopMenu>
        </HeaderContent>
      </HeaderBar>
    );
  }
}

export default HeaderMenu;
